import React from "react";

const Cookie = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Cookie Use Agreement</h1>
      <p>This is the content for the Cookie Use Agreement.</p>
    </div>
  );
};

export default Cookie;
