import React from "react";

const Terms = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      <p>
        This is the content for the Terms and Conditions. You can replace this
        text with actual content.
      </p>
    </div>
  );
};

export default Terms;
