import React from "react";

const Aml = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">AML/KYC</h1>
      <p>This is the content for the AML/KYC policy.</p>
    </div>
  );
};

export default Aml;
