import React from "react";

const Privacy = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">
        Personal Data Processing Policy
      </h1>
      <p>This is the content for the Personal Data Processing Policy.</p>
    </div>
  );
};

export default Privacy;
